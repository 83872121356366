.au-ts-container {
  padding: 100px 0;
}
.au-ts-caption-container {
  margin: 50px 10%;
}

#au-ts-caption {
  font-weight: 600;
  font-size: 24px;
}

.au-ts-text-container {
  display: flex;
  justify-content: flex-end;
}

#au-ts-logo {
  position: absolute;
  left: 0;
  top: 240px;
}

#au-ts-logo-mobile {
  display: none;
}

.au-ts-text-caption-container {
  position: absolute;
  left: 300px;
}

#au-ts-text-caption {
  font-weight: 700;
  font-size: 32px;
  color: #22245e;
}

.au-ts-text-description-container {
  font-size: 20px;
  font-weight: 400;
  color: #4e4b66;
  padding-right: 10%;
  width: 40%;
}

#au-ts-text-description-p2-highlight {
  color: #2a30bb;
  font-weight: 600;
}

@media (max-width: 862px) {
  .au-ts-caption-container {
    display: none;
  }
  #au-ts-logo {
    display: none;
  }

  #au-ts-logo-mobile {
    display: block;
    margin: 0 auto;
    margin-top: 20px;
    max-width: 300px;
    width: 90%;
  }

  .au-ts-text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .au-ts-text-caption-container {
    position: static;
    width: 100%;
    padding: 40px 5%;
    text-align: left;
    margin-top: -80px;
    background: white;
  }
  .au-ts-text-description-container {
    width: 100%;
    padding: 0 5%;
  }
}
