.au-om-container {
  margin-top: 50px;
  margin-bottom: 100px;
}

.au-om-accordion-container {
  padding: 5% 7%;
  max-width: 600px;
  margin: 0 auto;
}

.au-om-text-container {
  padding: 5% 7%;
  display: flex;
  flex-direction: column;
  max-width: 600px;
}

#au-om-title {
  font-size: 54px;
  font-weight: 700;
  color: #225cd1;
}

#au-om-caption {
  font-size: 15px;
  font-weight: 400;
  color: #4e4b66;
  margin-top: 30px;
}

@media (max-width: 959px) {
  .au-om-accordion-container {
    margin: 0;
  }

  .MuiGrid-align-content-xs-center {
    flex-direction: column-reverse;
  }

  #au-om-title {
    font-size: 32px;
  }
}
