#presentation-container {
  position: relative;
  top: 130px;
  background-color: #225cd1;
}

h1 {
  color: #fff;
}

#video {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 5%;
}

video {
  display: inline-block;
  position: relative;
}

@media (max-width: 768px) {
  #presentation-container {
    top: 115px;
  }
  #video {
    margin-bottom: 15%;
  }
}
