#main-card-container {
  width: 482px;
  height: 350px;
  position: absolute;
  background-color: #225cd1;
  top: 370px;
  left: 150px;
  border-top-left-radius: 29px;
  border-top-right-radius: 29px;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 49px;
}

.main-card-title {
  font-size: 40px;
  line-height: 48px;
  font-weight: 300;
  letter-spacing: -1.76471px;
  margin: 63px 0 0 0;
}

.main-card-content {
  text-align: center;
  font-size: 18px;
  line-height: 27px;
  font-weight: 300;
  height: 60px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.main-card-button {
  all: unset;
  cursor: pointer;
  width: 192px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border-radius: 40px;
  border: 2px solid transparent;
}

.main-card-button:hover {
  background-color: #225cd1;
  border-color: #fff;
}

.main-card-button a {
  text-decoration: none;
  color: #15b2db;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.75px;
}

.main-card-button:hover > a {
  color: #fff;
}

.dot {
  height: 4px;
  width: 50px;
  margin: 0 2px;
  background-color: #2a30bb;
  display: inline-block;
  transition: background-color 0.6s ease;
  border-radius: 4px;
}

.dot_container {
  display: flex;
  position: absolute;
  bottom: 44px;
}

.dot.active {
  background-color: #15b2db;
}

@media (max-width: 768px) {
  #main-card-container {
    width: calc(100% - 36px);
    max-width: 342px;
    height: 308px;
    left: 50%;
    top: 396px;
    transform: translateX(-50%);
    padding: 0 22px;
  }

  .main-card-title {
    font-size: 32px;
    line-height: 40px;
  }

  .main-card-content {
    font-size: 16px;
    line-height: 20px;
  }

  .main-card-button {
    width: 160px;
    height: 34px;
  }

  .dot_container {
    bottom: 35px;
  }

  .dot {
    width: 37.19px;
    height: 2.76px;
  }
}
