#news-container {
  height: 895px;
  width: 100%;
  position: relative;
  background-image: url(assets/img/newsBackground.png);
  background-repeat: no-repeat;
  background-position: 0 100%;
  background-size: 601px 776px;
  overflow: hidden;
}

.nc-dark {
  background-color: #225cd1;
  color: white;
}

.nc-light {
  background-color: #d9dbe9;
  color: #22245e;
}

.news-title {
  font-weight: bold;
  font-size: 42px;
  line-height: 51px;
  padding-top: 104px;
  margin-left: 150px;
  margin-top: 0;
}

.news-title-dark {
  color: #fff;
}

.news-title-light {
  color: #22245e;
}

.news-arrow_container {
  top: 80px;
  right: 148px;
  display: flex;
  justify-content: space-around;
  position: absolute;
  width: 141px;
}

.news-arrow {
  all: unset;
  width: 61px;
  height: 61px;
  cursor: pointer;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.news-arrow:disabled {
  opacity: 0.3;
}

.news-arrow .icon {
  display: inline-block;
  width: 28px;
  height: 28px;
  -webkit-mask-size: cover;
  mask-size: cover;
  background-color: #15b2db;
}

.news-arrow .left {
  -webkit-mask: url(assets/img/leftArrow.svg) no-repeat 50% 50%;
  mask: url(assets/img/leftArrow.svg) no-repeat 50% 50%;
}

.news-arrow .right {
  -webkit-mask: url(assets/img/rightArrow.svg) no-repeat 50% 50%;
  mask: url(assets/img/rightArrow.svg) no-repeat 50% 50%;
}

.news-swiper {
  width: 100%;
  margin-left: 144px;
  overflow: hidden;
}

.cards-container {
  display: flex;
  position: relative;
  width: 100%;
  transition: all 0.5s ease-out;
}

@media (max-width: 1440px) {
  .news-swiper {
    margin-left: 10px;
  }
}

@media (max-width: 768px) {
  #news-container {
    background-image: url(assets/img/newsBackground-mobile.png);
    height: 877px;
    width: 100%;
    background-position: 0 100%;
    background-size: auto;
  }

  .news-title {
    font-size: 28px;
    line-height: 51px;
    margin-left: 11px;
  }

  .news-swiper {
    margin-left: 0;
    padding-left: 11px;
    max-width: 100%;
  }

  .news-arrow_container {
    top: 97px;
    right: 16px;
    width: 126px;
  }
}
