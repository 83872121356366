.vc-bs-container {
  width: 100%;
  display: flex;
}

.vc-bs-right-container {
  justify-content: center;
  height: 100%;
  display: flex;
  align-items: center;
  margin-top: 150px;
}

.vc-bs-text {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 50%;
}

#bs-caption {
  color: #6e7191;
  font-weight: 400;
  font-size: 18px;
}

#bs-description {
  color: #4e4b66;
  font-weight: 400;
  font-size: 15px;
  padding-right: 70px;
}

#vc-bs-card-bottom-shape {
  width: 482px;
  height: 650px;
  background-color: #225cd1;
  margin-left: 150px;
  border-bottom-left-radius: 29px;
  border-bottom-right-radius: 29px;
  color: #fff;
  margin-right: 100px;
  position: relative;
}

.vc-bs-card-bottom-container {
  width: 482px;
  height: 650px;
  position: relative;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.little-card-container {
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 20px;
  border-radius: 13px;
  text-align: center;
  width: 230px;
  height: 340px;
  position: absolute;
  box-shadow: 0px 10px 20px rgb(0 0 0 / 20%);
}

#fast-little-card {
  top: 110px;
  left: -40px;
}

#targeted-little-card {
  top: 70px;
  right: -40px;
}

#efficient-little-card {
  bottom: -160px;
  right: 35px;
}

#bs-title {
  color: #22245e;
  font-weight: 700;
  font-size: 54px;
}

#vc-bs-imageContainer {
  text-align: end;
}

@media (max-width: 1440px) {
  .vc-bs-right-container {
    margin-top: 230px;
  }

  #vc-bs-image {
    width: 80%;
  }

  #vc-bs-card-bottom-shape {
    margin-bottom: 210px;
  }
}

@media (min-width: 1024px) {
  .vc-bs-text.animated {
    animation-name: right;
    animation-duration: 1s;
  }
  #efficient-little-card.animated {
    animation-name: bottom;
    animation-duration: 1s;
  }

  #targeted-little-card.animated {
    animation-name: top;
    animation-duration: 1s;
  }
  #fast-little-card.animated {
    animation-name: left;
    animation-duration: 1s;
  }
}

@media (max-width: 1024px) {
  .vc-bs-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 150px;
  }

  .vc-bs-right-container {
    margin-top: 150px;
    margin-left: 100px;
  }

  #vc-bs-image {
    display: none;
  }

  .vc-bs-text {
    margin-right: 100px;
  }
}

@media (max-width: 768px) {
  #vc-bs-card-bottom-shape {
    width: calc(100% - 36px);
    max-width: 342px;
    height: 308px;
    margin: 0 0 172px 0;
  }
  .vc-bs-container {
    margin-bottom: 94px;
    align-items: center;
  }
  .vc-bs-card-bottom-container {
    display: flex;
    overflow-x: scroll;
    width: 100%;
    padding-left: 43px;
    padding-top: 76px;
    height: 500px;
    position: absolute;
    left: 0;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .vc-bs-card-bottom-container::-webkit-scrollbar {
    display: none;
  }
  .little-card-container {
    flex-shrink: 0;
    margin-right: 16px;
    position: static;
  }
  .vc-bs-right-container {
    width: 100%;
    margin: 0 auto;
  }
  #vc-bs-image {
    display: none;
  }

  #bs-title {
    font-size: 36px;
    margin-bottom: 33px;
  }

  .vc-bs-text {
    width: 100%;
    margin: 0;
    padding: 0 17px 0 32px;
  }

  #bs-description {
    font-size: 14px;
    line-height: 24px;
  }
}

.vc-bs-card-bottom-container::-webkit-scrollbar {
  display: none;
}

@keyframes left {
  from {
    transform: translate(-200px, 0);
  }
  to {
    transform: translate(0, 0);
  }
}

@keyframes right {
  from {
    transform: translate(200px, 0);
  }
  to {
    transform: translate(0, 0);
  }
}

@keyframes top {
  from {
    transform: translate(0, -300px);
  }
  to {
    transform: translate(0, 0);
  }
}

@keyframes bottom {
  from {
    transform: translate(0, 300px);
  }
  to {
    transform: translate(0, 0);
  }
}
