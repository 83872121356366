#single-news-card-container {
  cursor: pointer;
}

.card-title {
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
  text-transform: uppercase;
  color: #22245e;
  text-align: left;
  padding: 0 22px;
}

.card-image {
  width: 100%;
  height: 274px;
}

.card-image img {
  width: 100%;
  height: 100%;
}

.card-container,
.card-container-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 380px;
  flex-shrink: 0;
  height: 497px;
  background-color: #fff;
  border-radius: 8px 8px 0px 0px;
  margin-right: 20px;
}

.card-container > span,
.card-container-column > span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
}

.news-tag-container {
  display: flex;
  margin-top: 30px;
  padding-left: 21px;
  padding-right: 30px;
}

.news-card-tag {
  font-weight: 600;
  font-size: 12px;
  line-height: 32px;
  color: #225cd1;
  text-transform: uppercase;
}

.news-card-date {
  font-size: 14px;
  line-height: 32px;
  text-transform: uppercase;
  color: #a0a3bd;
  padding-left: 21.48px;
}

@media (max-width: 798px) {
  .news-card-date,
  .card-title {
    padding-left: 18px;
    padding-right: 15px;
  }
  .card-container {
    width: 322px;
    height: 421px;
    margin-right: 15px;
  }

  .card-container-column {
    width: calc(100% - 50px);
    height: 421px;
    margin: 0 auto;
  }

  .card-image {
    height: 232px;
  }

  .card-title {
    font-size: 20px;
    line-height: 28px;
    margin-top: 9.45px;
  }
}
