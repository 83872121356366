.blue-banner-section {
  background-image: url(./blue-banner-shape.svg);
  background-color: #22245e;
  background-position-x: right;
  background-position-y: bottom;
  background-repeat: no-repeat;
  background-size: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 70px 20px;
  flex-wrap: wrap;
}
.blue-banner-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 25px 80px;
}
#blue-banner-caption {
  font-size: 20px;
  font-weight: 500;
  color: white;
}

#blue-banner-title {
  font-size: 64px;
  font-weight: 700;
  color: white;
}

#blue-banner-button {
  all: unset;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border-radius: 40px;
  border: 2px solid transparent;
  padding: 15px 30px;
}

#blue-banner-button a {
  text-decoration: none;
  color: #15b2db;
  font-weight: 900;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.75px;
}

@media (max-width: 705px) {
  .blue-banner-section {
    background-size: 250px;
  }
  .blue-banner-text {
    margin-bottom: 50px;
  }
}
