.wwd-top-container {
  top: 720px;
  position: absolute;
  width: 100%;
}

.wwd-card-container {
  background-color: white;
  text-align: center;
  padding: 80px 55px;
  margin: 0 auto;
  max-width: 95%;
  height: 545px;
  box-shadow: 0px 20px 90px rgb(0 0 0 / 20%);
}

.wwd-title-one {
  font-size: 18px;
  font-weight: 500;
  color: #6e7191;
}
.wwd-title-two {
  font-size: 34px;
  font-weight: 700;
  color: #22245e;
}
.wwd-container {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.wwd-item-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 45px !important;
}

.wwd-item-title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 15px;
  color: #222a41;
}

.wwd-item-description {
  font-size: 15px;
  font-weight: 400;
  color: #6d7783;
}

.wwd-arrow {
  display: flex;
}

@media (min-width: 320px) and (max-width: 768px) {
  .wwd-top-container {
    position: static;
    top: 0;
  }
}

@media (max-width: 960px) {
  .wwd-card-container {
    max-width: 960px;
    height: auto;
    box-shadow: 0px 69px 84px rgb(0 0 0 / 20%);
  }
  .wwd-arrow {
    display: none;
  }
  .wwd-item-container {
    align-items: flex-start;
    text-align: start;
  }

  .wwd-title-container {
    text-align: start;
  }
}
