#solutions-card-container {
  width: 305px;
  height: 368px;
  max-width: 305px;
  max-height: 368px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 20px;
  margin-right: 22px;
  padding-top: 38px;
  padding-left: 36px;
  padding-right: 24px;
  cursor: pointer;
}

#solutions-card-container:hover {
  background-color: #fff;
}

#solutions-card-container:hover #solutions-button-arrow {
  background-color: #fff;
}

#solutions-card-container > .icon {
  display: inline-block;
  width: 79px;
  height: 79px;
  background-color: #225cd1;
}

#solutions-card-container:hover > .icon,
#solutions-card-container:hover > a {
  background-color: #15b2db;
  color: #fff;
  padding: 10px 20px;
}
#solutions-card-container:hover > a span {
  color: #fff;
}

#solutions-card-container > a span {
  color: #15b2db;
}
#solutions-card-container:hover > h5 {
  color: #15b2db;
}

#microAnalizerIcon {
  -webkit-mask: url(assets/img/microAnalizerIcon.svg) no-repeat 50% 50%;
  mask: url(assets/img/microAnalizerIcon.svg) no-repeat 50% 50%;
}

#covidIcon {
  -webkit-mask: url(assets/img/covidIcon.svg) no-repeat 50% 50%;
  mask: url(assets/img/covidIcon.svg) no-repeat 50% 50%;
}

#d24Icon {
  -webkit-mask: url(assets/img/d24Icon.svg) no-repeat 50% 50%;
  mask: url(assets/img/d24Icon.svg) no-repeat 50% 50%;
}

#onBoardingIcon {
  -webkit-mask: url(assets/img/onBoardingIcon.svg) no-repeat 50% 50%;
  mask: url(assets/img/onBoardingIcon.svg) no-repeat 50% 50%;
}

#crowdControlIcon {
  -webkit-mask: url(assets/img/crowdControlIcon.svg) no-repeat 50% 50%;
  mask: url(assets/img/crowdControlIcon.svg) no-repeat 50% 50%;
}

#solutions-card-container > h5 {
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  color: #225cd1;
  margin-top: 10px;
  margin-bottom: 15px;
}

#solutions-card-container > p {
  font-size: 16px;
  line-height: 24px;
  color: #4e4b66;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  height: 70px;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

#solutions-card-container > a {
  text-decoration: none;
  width: 170px;
  height: 45px;
  border-radius: 31px;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.75px;
  text-transform: uppercase;
  color: #a0a3bd;
  margin-top: 15px;
}

#solutions-button-arrow {
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-left: 10px;
  background-color: #eff0f6;
  -webkit-mask: url(assets/img/rightArrow.svg) no-repeat 50% 50%;
  mask: url(assets/img/rightArrow.svg) no-repeat 50% 50%;
}

@media (max-width: 768px) {
  #solutions-card-container {
    width: 100%;
    height: auto;
    flex-shrink: 0;
    margin-right: 0;
    padding: 35px 25px;
  }

  #solutions-card-container > .icon {
    width: 65px;
    height: 65px;
    -webkit-mask-size: contain;
    mask-size: contain;
  }
  #solutions-card-container > h5 {
    font-size: 20px;
    margin-bottom: 0;
  }
  #solutions-card-container > p {
    max-width: 60%;
    font-size: 14px;
    height: auto;
  }

  #solutions-card-container > a {
    margin-top: 0;
    width: auto;
  }
}
