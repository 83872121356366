.au-s-container {
  min-height: 775px;
  background-color: #225cd1;
  padding-bottom: 100px;
}

.au-s-text-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.au-s-text {
  padding: 5% 7%;
  max-width: 800px;
  margin: 0 auto;
  font-size: 42px;
  font-weight: 700;
  color: white;
}

#au-s-title {
  margin: 8px 0;
  max-width: 400px;
}

#au-s-button {
  all: unset;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  border: 2px solid white;
  padding: 20px 65px;
}

#au-s-button a {
  text-decoration: none;
  color: white;
  font-weight: 900;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.75px;
}

.au-s-slider-container {
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.au-s-slider-container::-webkit-scrollbar {
  display: none;
}

.au-s-card-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 240px;
  min-width: 240px;
  height: 340px;
  max-height: 340px;
  color: white;
  margin: 30px;
  padding: 35px;
  border-radius: 20px;
}

.au-s-card-container:hover {
  background-color: #eff0f6;
  color: #225cd1;
}

.au-s-card-container:hover .au-s-card-image {
  color: #225cd1;
}

.au-s-card-container:hover #au-s-card-description {
  color: #225cd1;
}

#au-s-card-title {
  font-size: 24px;
  font-weight: 700;
}

#au-s-card-description {
  font-size: 16px;
  font-weight: 400;
  color: #d9dbe9;
}

#au-s-card-button {
  font-size: 16px;
  font-weight: 600;
}

#au-s-card-button-high {
  font-size: 16px;
  font-weight: 600;
  color: #15b2db;
  margin-left: 10px;
}

#au-s-km-button-container {
  cursor: pointer;
}

@media (max-width: 1024px) {
  .au-s-slider-container {
    justify-content: flex-start;
    padding: 25px;
  }
  .au-s-card-container {
    margin: 50px 10px;
  }
}

@media (max-width: 440px) {
  .au-s-text {
    font-size: 32px;
    font-weight: 700;
  }
  #au-s-button {
    padding: 10px 35px;
  }
}

@media (max-width: 888px) {
  .au-s-container {
    position: relative;
  }
  .au-s-button-container {
    position: absolute;
    bottom: 20px;
  }
}
