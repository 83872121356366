.trigger-custom-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px;
}

.trigger-closed {
  color: #a0a3bd;
  font-size: 24px;
  font-weight: 500;
  border-bottom: 1px solid #d9dbe9;
}

.trigger-closed:hover {
  color: #225cd1;
  background-color: #eff0f6;
}

.trigger-open {
  font-size: 24px;
  font-weight: 500;
  color: #225cd1;
  background-color: #eff0f6;
}

.trigger-content {
  padding: 20px 20px;
  font-size: 15px;
  font-weight: 400;
  color: #4e4b66;
  background-color: #f9f9fd;
}
