#solutions-slider-container {
  position: relative;
  background-color: #eff0f6;
  overflow: hidden;
}

#solution-cards-container {
  display: flex;
  position: relative;
  width: 100%;
  margin-left: 100px;
  transition: all 0.5s ease-out;
  padding: 0 10px;
}

#solutions-header-container {
  display: flex;
  margin-top: 134px;
  margin-bottom: 60px;
  justify-content: space-between;
  align-items: center;
}
#solutions-title {
  font-weight: bold;
  font-size: 34px;
  line-height: 51px;
  color: #225cd1;
  margin-left: 145px;
}

.solutions.arrow_container {
  display: flex;
  justify-content: space-around;
  width: 131px;
  margin-right: 72px;
}

.solutions-arrow {
  all: unset;
  width: 61px;
  height: 61px;
  cursor: pointer;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.solutions-arrow:disabled {
  opacity: 0.4;
}

.solutions-arrow:hover:not(:disabled) {
  background-color: #15b2db;
}

.solutions-arrow:hover:not(:disabled) > .icon {
  background-color: #fff;
}

.solutions-arrow .icon {
  display: inline-block;
  width: 28px;
  height: 28px;
  -webkit-mask-size: cover;
  mask-size: cover;
  background-color: #15b2db;
}

.solutions-arrow .left {
  -webkit-mask: url(assets/img/leftArrow.svg) no-repeat 50% 50%;
  mask: url(assets/img/leftArrow.svg) no-repeat 50% 50%;
}

.solutions-arrow .right {
  -webkit-mask: url(assets/img/rightArrow.svg) no-repeat 50% 50%;
  mask: url(assets/img/rightArrow.svg) no-repeat 50% 50%;
}

@media (min-width: 1024px) {
  #solutions-title.animated {
    animation-name: titleleft;
    animation-duration: 1s;
  }
}

@media (max-width: 1024px) {
  #solutions-title {
    margin-left: 50px;
    justify-content: space-evenly;
  }
}

@media (max-width: 768px) {
  #solutions-slider-container {
    display: flex;
    flex-direction: column;
  }

  #solution-cards-container {
    flex-direction: column;
    margin-left: 0px;
    padding-bottom: 90px;
    align-items: center;
  }

  #solutions-title {
    margin-left: 40px;
  }
}

@keyframes titleleft {
  from {
    transform: translate(-200px, 0);
  }
  to {
    transform: translate(0, 0);
  }
}
