.footer-section-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background-color: #eff0f6;
}

.footer-logo-container {
  height: 350px;
  display: flex;
  flex: 1;
  justify-content: center;
  padding: 3%;
}

.footer-links-container {
  height: 350px;
  display: flex;
  align-items: center;
  flex: 1;
  padding: 3%;
}

.footer-links-first-column {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.footer-links-second-column {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.footer-link-container {
  display: flex;
  align-items: center;
  flex: 1;
}
.footer-link-container > a {
  font-size: 24px;
  color: #6e7191;
}

.footer-social-container {
  height: 350px;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3%;
  background-color: #f7f7fc;
}

#footer-social-text {
  font-weight: 500;
  font-size: 18px;
  color: #6e7191;
  padding: 25px;
}

.footer-social-icons-container {
  display: flex;
  justify-content: space-evenly;
  padding: 25px;
}

.footer-social-icon {
  padding: 0 15px;
}

#footer-bottom-segment {
  height: 25px;
  width: 100%;
  background-color: #22245e;
}

@media (max-width: 646px) {
  .footer-social-container {
    height: 250px;
  }
  .footer-logo-container {
    height: 250px;
  }
  .footer-links-container {
    display: none;
  }
}
