.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: #225cd1;
}

.loading-spinner {
  color: white;
}
