* {
  box-sizing: border-box;
}

#main-swiper {
  position: relative;
  margin: 0 auto;
  margin-bottom: 266px;
}

#main-swiper .arrow_container {
  display: flex;
  justify-content: space-around;
  position: absolute;
  width: 135px;
  top: 600px;
  right: 80px;
}

#main-swiper .arrow {
  all: unset;
  cursor: pointer;
  width: 65px;
  height: 65px;
  background-color: #15b2db;
  display: flex;
  justify-content: center;
  align-items: center;
}

#main-swiper .arrow:disabled {
  background-color: #fff;
}

#main-swiper .arrow:disabled > .icon {
  background-color: #d9dbe9;
}

#main-swiper .arrow:hover:not(:disabled) {
  background-color: #fff;
}

#main-swiper .arrow:hover:not(:disabled) > .icon {
  background-color: #15b2db;
}

#main-swiper .icon {
  display: inline-block;
  width: 28px;
  height: 28px;
  -webkit-mask-size: cover;
  mask-size: cover;
  background-color: #fff;
}

#main-swiper .icon.left {
  -webkit-mask: url(assets/img/leftArrow.svg) no-repeat 50% 50%;
  mask: url(assets/img/leftArrow.svg) no-repeat 50% 50%;
}

#main-swiper .icon.right {
  -webkit-mask: url(assets/img/rightArrow.svg) no-repeat 50% 50%;
  mask: url(assets/img/rightArrow.svg) no-repeat 50% 50%;
}

.slideshow-container {
  position: relative;
  margin: auto;
  height: 1000px;
  overflow: hidden;
}

.mySlides.hidden {
  display: none;
}

.mySlides img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-name: fade;
  animation-duration: 1.5s;
}

@media (max-width: 768px) {
  .mySlides img {
    height: auto;
    min-height: 544px;
  }

  #main-swiper .arrow_container {
    width: 96px;
    top: 370px;
    right: 47px;
  }

  #main-swiper .arrow {
    width: 45px;
    height: 45px;
  }

  #main-swiper .icon {
    width: 20px;
    height: 20px;
  }

  #main-swiper {
    margin-bottom: 140px;
  }

  .slideshow-container {
    height: auto;
  }
}

@-webkit-keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}
