.sn-top-container {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding-top: 150px;
  padding-bottom: 100px;
}

.sn-video-container {
  padding: 50px;
  position: relative;
}

#sn-video-blue-bg {
  width: 60%;
  height: 750px;
  background-color: #225cd1;
  position: absolute;
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
}

.sn-social-media-container {
  position: absolute;
  bottom: -20px;
  right: 100px;
}

.sn-content-container {
  width: 100%;
  padding: 0 15px;
  max-width: 1450px;
  margin: 0 auto;
}

.sn-left-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
}

.sn-left-date {
  font-size: 14px;
  color: #225cd1;
  margin-bottom: 30px;
}

.sn-left-title {
  font-size: 36px;
  font-weight: 500;
  color: #22245e;
  margin-bottom: 50px;
}

.sn-left-description {
  font-size: 20px;
  color: #6e7191;
  margin-bottom: 50px;
}

.sn-left-sm {
  font-size: 14px;
  color: #6e7191;
  margin-bottom: 10px;
}

.sn-right-title-menu {
  font-size: 20px;
  font-weight: 500;
  color: #225cd1;
}

.sn-right-container-menu {
  margin-bottom: 100px;
}

.sn-menu-divider {
  height: 5px;
  width: 100px;
  background-color: #22245e;
  margin-bottom: 20px;
}

@media (max-width: 1000px) {
  .sn-top-container {
    padding-top: 100px;
    padding-bottom: 0px;
  }
}

@media (max-width: 976px) {
  .sn-social-media-container {
    position: unset;
    display: flex;
    justify-content: center;
    margin-top: 100px;
  }
  #sn-video-frame {
    height: 450px;
  }
  #sn-video-blue-bg {
    height: 550px;
  }
}

@media (max-width: 650px) {
  .sn-social-media-container {
    position: unset;
    display: flex;
    justify-content: center;
    margin-top: 100px;
  }
  .sn-video-container {
    padding: 30px 10px;
  }
  #sn-video-frame {
    height: 250px;
  }
  #sn-video-blue-bg {
    height: 320px;
  }
}
