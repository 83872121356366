.au-o-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eff0f6;
  padding: 4% 0;
}

.au-o-text-container {
  padding: 4% 10%;
}

#au-o-caption {
  font-size: 18px;
  color: #6e7191;
}
#au-o-title {
  font-size: 44px;
  font-weight: 700;
  color: #22245e;
}
#au-o-description {
  font-size: 15px;
  color: #4e4b66;
}

.au-o-card-container {
  height: 425px;
  display: flex;
  justify-content: flex-start;
  overflow-x: scroll;
  align-items: center;
  padding: 0 7%;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.au-o-card-container::-webkit-scrollbar {
  display: none;
}

.au-little-card {
  background: white;
  border-radius: 13px;
  width: 230px;
  height: 340px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: auto;
  margin: 0 20px;
}

.au-little-card-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 20px;
  text-align: center;
}
