#featured {
  display: flex;
  margin: 0 auto;
  justify-content: space-around;
  align-items: center;
  align-content: space-around;
}
#ft-img {
  width: 778px;
  height: 592px;
  margin-right: 24px;
}

#ft-sidecontent {
  display: flex;
  flex-direction: column;
  width: 486px;
  height: 592px;
  align-items: flex-start;
  font-size: 36px;
  font-weight: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #22245e;
  padding: 109px 49px 0;
}

#ft-new-title {
  height: 200px;
  padding: 10px 10px 10px 0;
}

#ft-new-title span {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.ft-ind-name {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
  line-height: 32px;
  color: #225cd1;
}

#ft-new-date {
  font-size: 14px;
  line-height: 32px;
  text-transform: uppercase;
  color: #a0a3bd;
}

#ft-new-button {
  width: 190px;
  height: 74px;
  background: #15b2db;
  border-radius: 40px;
  color: #fff;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  letter-spacing: 0.75px;
  text-transform: uppercase;
  font-weight: 900;
  align-self: center;
  margin-top: 76px;
}

@media (max-width: 1024px) {
  #featured {
    width: calc(100% - 36px);
    flex-direction: column;
    margin: 0 auto;
  }

  #ft-img {
    width: 100%;
    height: auto;
    margin-bottom: 12px;
    margin-right: 0;
  }
}

@media (max-width: 768px) {
  #featured img {
    width: 100%;
    height: auto;
    margin-bottom: 12px;
  }

  #ft-sidecontent {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    height: 355px;
    font-size: 24px;
    padding: 24px 26px 0;
  }

  #ft-new-title {
    height: 115px;
    padding: 10px 10px 10px 0;
  }

  #ft-new-button {
    width: 197px;
    height: 57px;
    font-size: 16px;
    margin-top: 39px;
  }
}
