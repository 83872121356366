#ss-cover-container {
  width: 100%;
  height: 512px;
}

#ss-cover-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}

#ss-content {
  display: flex;
  margin-bottom: 145px;
}

#ss-info-container {
  display: flex;
  flex-direction: column;
  position: relative;
  bottom: 176px;
  margin-left: 226px;
  margin-right: 101px;
  width: 555px;
}

#ss-title {
  height: 233px;
  background-color: #225cd1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 40px;
  line-height: 52px;
  color: #fff;
  border-radius: 10px 10px 0px 0px;
}

#ss-subtitle {
  font-weight: 600;
  font-size: 28px;
  line-height: 44px;
  color: #6e7191;
  margin-top: 79px;
  margin-bottom: 52px;
}
#ss-description-title {
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  color: #225cd1;
  margin-bottom: 24px;
}
#ss-description {
  font-size: 20px;
  line-height: 32px;
  color: #4e4b66;
}

#ss-blue-border {
  height: 373px;
  width: 100%;
  background: #225cd1;
  border-radius: 90px 0px 0px 0px;
  position: relative;
}

#ss-video {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

#ss-ind-title {
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  color: #225cd1;
  margin-top: 136px;
}

@media (max-width: 768px) {
  #ss-cover-container {
    height: 376px;
  }

  #ss-info-container {
    bottom: 176px;
    margin: 0 auto;
    width: 338px;
    bottom: 150px;
  }

  #ss-content {
    flex-direction: column;
    margin-bottom: 145px;
  }

  #ss-title {
    font-size: 28px;
  }

  #ss-subtitle {
    font-size: 18px;
  }

  #ss-description {
    font-size: 14px;
  }

  #ss-ind-title {
    margin-top: 0;
  }

  #ss-industries {
    margin-left: 33px
  }
}
