.ss-industry {
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.75px;
  text-transform: uppercase;
  color: #6e7191;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-right: 160px;
  margin: 16px 0;
}

.ss-ind-img {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 19.5px;
}
