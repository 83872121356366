.section-tp-container {
  height: 850px;
  position: relative;
  display: flex;
  justify-content: center;
  background-color: #eff0f6;
}

.section-tp-absolute {
  width: 100vw;
  position: absolute;
  top: -100px;
}

.section-tp-card-container {
  width: 100%;
  max-width: 1000px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 0 auto;
}

.section-cl-filter-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 auto;
  margin-bottom: 60px;
  max-width: 1200px;
  position: absolute;
  top: -180px;
}

.section-cl-filter-button {
  padding: 13px 28px;
  border-radius: 40px;
  cursor: pointer;
  margin-right: 10px;
}

.section-cl-filter-button:hover {
  background-color: #eff0f6;
}

.section-cl-filter-button-active {
  background-color: #eff0f6;
}

.section-cl-filter-button-text-active {
  color: #225cd1 !important;
}

.section-cl-filter-button:hover .section-cl-filter-button-text {
  color: #225cd1;
}

.section-cl-filter-button-text {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.75;
  color: #6e7191;
}

.section-cl-card-container {
  display: flex;
  justify-content: center;
  margin: 20px;
}

.section-cl-card {
  display: flex;
  flex-direction: column;
  width: 290px;
  height: 380px;
  box-shadow: 0px 20px 90px rgb(0 0 0 / 20%);
  border-radius: 8px 8px 0px 0px;
  background-color: white;
}

.section-cl-card:hover .section-cl-card-bottom {
  background-position: top left;
}

.section-cl-card:hover .section-cl-card-icon-container {
  display: none;
}

.section-cl-card:hover .section-cl-card-button-container {
  display: flex;
  justify-content: flex-end;
}

.section-cl-card:hover #section-cl-card-title {
  color: white;
}

.section-cl-card:hover #section-cl-card-description {
  color: white;
}

.section-cl-card-top {
  flex: 0.8;
  border-radius: 8px 8px 0px 0px;
  background-color: #225cd1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.section-cl-card-bottom {
  flex: 1;
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background: linear-gradient(to top, #fff 50%, #225cd1 50%);
  background-size: 100% 200%;
  background-position: bottom left;
  transition: all 0.5s ease-out;
}

#section-cl-card-title {
  font-size: 24px;
  font-weight: 700;
  color: #22245e;
}

#section-cl-card-description-container {
  height: 88px;
}
#section-cl-card-description {
  all: unset;
  font-size: 14px;
  font-weight: 400;
  color: #4e4b66;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.section-cl-card-icon-container {
  display: flex;
  justify-content: flex-end;
}

.section-cl-card-button-container {
  display: none;
}

#section-cl-card-icon {
  color: #15b2db;
}

#section-cl-card-button-hover {
  padding: 5px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background-color: #15b2db;
  cursor: pointer;
}

#section-cl-card-icon-hover {
  color: white;
}

#section-cl-card-button-text {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.75;
  color: white;
}

@media (max-width: 959px) {
  .section-tp-container {
    height: 1300px;
  }
}

@media (max-width: 599px) {
  .section-tp-container {
    height: 2300px;
  }
}

#section-cl-filter-button-select {
  display: none;
}

@media (max-width: 773px) {
  .section-cl-filter-button {
    display: none;
  }
  #section-cl-filter-button-select {
    display: block;
  }

  #section-cl-card-description-container {
    height: 79px;
  }
}
