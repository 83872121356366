.navbar-header-container {
  width: 100vw;
  background: rgba(63, 63, 63, 0.6);
  position: fixed;
  transition: background-color 0.7s ease;
  padding: 30px 7%;
  z-index: 10;
}

.navbar-header-dark {
  background: #225cd1;
}

.navbar-header-mobile {
  padding: 15px 5%;
  background-color: white;
}

.navbar-container {
  flex-direction: row;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1750px;
}

.navbar-logo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.navbar-logo-text {
  font-size: 13px;
  color: white;
  font-weight: 500;
  margin-top: 10px;
}

.navbar-logo-text-mobile {
  font-size: 13px;
  color: black;
  font-weight: 500;
  margin-top: 5px;
}

.navbar-image-logo {
  width: 285px;
  cursor: pointer;
}

.navbar-link {
  text-decoration: none;
  color: white;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-right: 30px;
  cursor: pointer;
}

.navbar-select {
  padding: 5px 10px;
  background: transparent;
  border-width: 1px;
  border-radius: 50px;
  color: white;
  font-family: 'Livvic';
  border-color: white;
}

.navbar-language-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar-span-language {
  margin-left: 10px;
}

.navbar-menu-mobile-container {
  position: fixed;
  z-index: 99;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #15b2db;
  background-image: url('assets/img/menu-mobile-background.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;
}

.navbar-close-icon {
  color: white;
  position: absolute;
  top: 25px;
  right: 35px;
}

.navbar-link-container {
  display: flex;
  flex-direction: column;
  height: 230px;
  justify-content: space-around;
}
.navbar-link-mobile {
  font-size: 20px;
}

@media (max-width: 420px) {
  .navbar-image-logo {
    width: 180px;
    cursor: pointer;
  }
  .navbar-logo-text-mobile {
    font-size: 10px;
  }
  
}
