#all-news-container-top {
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  position: relative;
}

#all-news-container-top::before {
  content: '';
  background-color: #eff0f6;
  position: absolute;
  height: 241px;
  width: 100%;
  bottom: 0;
  z-index: -10;
}

#all-news-grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(337px, 414px));
  grid-gap: 24px;
  justify-content: center;
}

#all-news-container-bottom {
  background-color: #eff0f6;
  padding-bottom: 266px;
}

#all-news-heading {
  height: 238px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

#all-news-heading-title {
  font-size: 24px;
  line-height: 32px;
  text-transform: uppercase;
  font-weight: bold;
  color: #225cd1;
}

#all-news-sub {
  font-size: 14px;
  line-height: 32px;
  text-transform: uppercase;
  color: #a0a3bd;
  padding-left: 90px;
  padding-top: 39px;
}

#all-news-tit {
  font-size: 24px;
  line-height: 32px;
  text-transform: uppercase;
  font-weight: bold;
  color: #225cd1;
  padding-left: 90px;
  padding-bottom: 25px;
}

@media (max-width: 768px) {
  #all-news-card {
    margin-right: 0;
  }

  #all-news-sub {
    padding-top: 0;
    padding-left: 20px;
  }

  #all-news-tit {
    padding-left: 20px;
  }

  #all-news-heading {
    flex-direction: column;
    justify-content: space-evenly;
  }
}
