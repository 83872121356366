.solutions-container {
  padding: 100px 0 300px 0;
}

.solutions-text-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 130px;
}

.solutions-text-caption-container {
  position: absolute;
  left: 300px;
}

.solutions-text-description-container {
  font-size: 40px;
  font-weight: 700;
  color: #4e4b66;
  padding-right: 20%;
  width: 50%;
}

#solutions-logo {
  position: absolute;
  left: 0;
  top: 240px;
}

#solutions-logo-mobile {
  display: none;
}

#solutions-text-caption {
  font-weight: 700;
  font-size: 32px;
  color: #22245e;
}

@media (max-width: 1044px) {
  #solutions-logo {
    display: none;
  }
  #solutions-logo-mobile {
    display: block;
    margin: 0 auto;
    margin-top: 20px;
    max-width: 300px;
    width: 90%;
  }

  .solutions-text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0;
  }
  .solutions-text-caption-container {
    position: static;
    width: 100%;
    padding: 40px 5%;
    text-align: left;
    margin-top: -80px;
    background: white;
  }
  .solutions-text-description-container {
    width: 100%;
    padding: 0 3%;
  }
}
