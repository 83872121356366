#solutions-sideContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: #fff;
  width: 490px;
  height: 807px;
  padding: 0 146px;
}
.sideContent-secondary {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.5;
  text-align: left;
  color: #a0a3bd;
  display: block;
}

.sideContent-main {
  font-size: 34px;
  font-weight: bold;
  line-height: 1.06;
  text-align: left;
  color: #22245e;
  margin-top: 13px;
}
#sidecontent-button {
  background-color: #15b2db;
  width: 195px;
  height: 73.47px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.75px;
  text-transform: uppercase;
  color: #f7f7fc;
  margin-top: 26px;
  cursor: pointer;
}

@media (min-width: 1024px) {
  #sideContent-text-container.animated {
    animation-name: animatedText;
    animation-duration: 1s;
  }

  #sidecontent-button.animated {
    animation-name: animatedButton;
    animation-duration: 0.5s;
  }
}

@media (max-width: 768px) {
  #solutions-sideContent {
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    align-items: center;
    justify-content: center;
    height: 200px;
    width: 100%;
  }

  .sideContent-main {
    font-size: 28px;
  }

  #sidecontent-button {
    width: 149px;
    height: 49px;
    font-size: 18px;
    flex-shrink: 0;
  }
}

@keyframes animatedText {
  from {
    transform: translate(100px, 0);
  }
  to {
    transform: translate(0, 0);
  }
}

@keyframes animatedButton {
  from {
    transform: translate(0, -400px);
  }
  to {
    transform: translate(0, 0);
  }
}
