input[type='checkbox'] {
  transform: scale(1.5);
}

.cu-container {
  padding: 7%;
  background-repeat: no-repeat;
  background-size: cover;
}

.cu-container-ar {
  background-position: left;
  background-image: url('./maps-ar.jpg');
}

.cu-container-co {
  background-position: right;
  background-image: url('./maps-co.jpg');
}

.cu-container-usa {
  background-position: right;
  background-image: url('./maps-usa.jpg');
}

.cu-card-container {
  margin-top: 100px;
  width: 630px;
  background: #22245e;
  padding-bottom: 60px;
}

.cu-country-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 50px;
  padding: 30px 10px;
}

.cu-country-label {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.75;
  padding-bottom: 5px;
  color: white;
  cursor: pointer;
}

.cu-country-label-active {
  border-bottom-width: 2px;
  border-bottom-style: solid;
  font-weight: 700;
}

#cu-map-image {
  display: none;
}

.input {
  margin: 10px;
  padding: 0 20px;
  height: 45px;
  width: 250px;
  border-radius: 10px;
  border: 0px;
  font-family: 'Livvic';
}

.cu-input-row-container {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.cu-input-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.cu-input-label {
  margin-left: 10px;
  font-size: 16px;
  font-weight: 600;
  color: white;
}

.cu-text-area-row-container {
  margin: 0 auto;
  display: flex;
  justify-content: center;
}
.cu-text-area-container {
  width: 570px;
  display: flex;
  flex-direction: column;
}

.cu-text-area {
  margin: 10px;
  padding: 10px 20px;
  border-radius: 10px;
  border: 0px;
  font-family: 'Livvic';
}

.cu-checkbox-container {
  margin: 20px auto;
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cu-checkbox-label {
  margin-left: 10px;
  font-size: 16px;
  font-weight: 400;
  color: white;
}

#cu-button {
  all: unset;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border-radius: 40px;
  border: 2px solid transparent;
  padding: 10px 70px;
}

#cu-button a {
  text-decoration: none;
  color: #225cd1;
  font-weight: 900;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.75px;
}

@media (max-width: 1400px) {
  .cu-container {
    padding: 0;
    background-image: none;
  }
  #cu-card-container {
    background-color: #22245e;
  }

  #cu-map-image {
    display: block;
    width: 100%;
  }

  .cu-card-container {
    margin: 0 auto;
    width: 100%;
    max-width: 630px;
  }

  .cu-country-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
  }

  .cu-country-label {
    margin: 10px 30px;
    font-size: 18px;
  }

  .cu-country-label-active {
    font-size: 18px;
  }
}

@media (max-width: 539px) {
  .cu-text-area-container {
    width: 270px;
  }
}
